"use strict";

// bs-table-item-selection-actions

var _require = require('lodash'),
  get = _require.get;
var tableItemSelectionActionsCtrl = /* @ngInject */function tableItemSelectionActionsCtrl($route, $routeParams, $scope, $q, $rootScope, $window, $timeout, eventService, metadataService, bulkUpdateService, metricsService, modalService) {
  var _this = this;
  this.$window = $window;
  this.$timeout = $timeout;

  // ⚑
  // TODO: Refactor bad directive practice
  var eventId = $routeParams.eventId;
  var event = eventService.decorateScope(eventId);

  // batch selection actions to be used in the pax & agenda tables
  var rows = function rows() {
    return $scope.rowCollection;
  };
  var getAllSelectedItems = function getAllSelectedItems() {
    return _.where(rows(), {
      isSelected: true
    });
  };
  var getDocFpType = function getDocFpType() {
    return (_.first(rows()) || {}).fp_type;
  };
  var deleteMetaPropsInRow = function deleteMetaPropsInRow(row) {
    var privateFields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var fieldsToDelete = _.union(
    // just to be safe (these two are set in the controller for display purposes)
    ['isInvited', 'isActivated', 'actcode'], privateFields);
    return _.omit(row, fieldsToDelete);
  };
  var getPrivateFields = function getPrivateFields() {
    var metadata = metadataService.getCachedMetadataForTypeAsArray(eventId, getDocFpType());
    return metadata.filter(function (field) {
      return field.crypt;
    }).map(function (field) {
      return field.field;
    });
  };
  $scope.getSelectedItemCount = function () {
    return getAllSelectedItems().length;
  };
  $scope.getDeleteButtonLabel = function () {
    if (getDocFpType() === 'person') {
      return 'Deactivate and cancel';
    }
    return 'Delete';
  };
  $scope.shouldShowSelectionOperationsButtons = function () {
    $scope.somethingSelected = Boolean(_.findWhere(rows(), {
      isSelected: true
    }));
    // try to do something a bit optimised
    return $scope.somethingSelected;
  };
  $scope.hideSelectedItemsInList = function () {
    var selectedItems = getAllSelectedItems();
    var _rows = _.without.apply(_, [rows()].concat(selectedItems));
    return angular.copy(_rows, rows());
  };
  $scope.shouldShowHideShowButtons = function () {
    return getDocFpType() === 'person';
  };
  $scope.shouldShowPaxRequestButtons = function () {
    return getDocFpType() === 'pax-request';
  };
  $scope.shouldShowDeleteButtons = function () {
    var currentRouteDescriptor = $route.current !== null ? $route.current.$$route : null;
    var blueprintController = get(currentRouteDescriptor, 'blueprintMetadata.controller');
    var hideDelete = get(blueprintController, 'routeOptions.crudOpts.hideDelete');
    return !hideDelete;
  };
  $scope.shouldShowDuplicateButton = function () {
    return metadataService.getCachedDuplicateFlagForType(eventId, getDocFpType());
  };
  $scope.shouldShowBulkEditButton = function () {
    return metadataService.getCachedBulkEditFlagForType(eventId, getDocFpType());
  };
  $scope.shouldShowTimeShiftButton = function () {
    return window.BSTG.services.metadata.isFpTypeTimeShiftable(eventId, getDocFpType());
  };
  $scope.applyFpStatusToSelectedItems = function (fpStatusToApply, attributeName) {
    if (!attributeName) {
      attributeName = 'fp_status';
    }
    var promises = [];
    var selectedItems = getAllSelectedItems();
    var privateFields = getPrivateFields();
    selectedItems.forEach(function (_row) {
      delete _row.isSelected;
      var clonedRow = deleteMetaPropsInRow(_.clone(_row), privateFields);
      clonedRow[attributeName] = fpStatusToApply;
      var p = bulkUpdateService.updateDoc(eventId, event.node, _row.fp_type, clonedRow);
      promises.push(p);
      p.then(function () {
        return _row[attributeName] = clonedRow[attributeName];
      });
    });
    return $q.all(promises).then(function () {
      $scope.onStatusChange({
        status: fpStatusToApply,
        selectedItems: selectedItems
      });
      return $scope.$emit('selectionChange');
    });
  };

  /** @param {'approve' | 'deny'} action */
  $scope.setPaxRequestStatus = function (action) {
    var items = getAllSelectedItems() || [];
    if (!items.length || !['approve', 'deny'].includes(action)) {
      return;
    }
    var msgAction = action === 'approve' ? 'approved' : 'denied';
    var msg = "The selected requests will be ".concat(msgAction, ". Proceed?");
    if (!confirm(msg)) {
      return;
    }
    var paxRequestIds = items.map(function (d) {
      return d._id;
    });
    window.BSTG.services.pax.setPaxRequestStatus(eventId, paxRequestIds, action).then(function () {
      return $route.reload();
    }).catch(function () {
      return alert('The requests could not be approved');
    });
  };
  $scope.deleteSelectedItems = function () {
    var items = getAllSelectedItems() || [];
    if (!items.length) {
      return;
    }
    if (!confirm('The selected items will be removed or deactivated. Proceed?')) {
      return;
    }
    if (getDocFpType() === 'person') {
      return $scope.applyFpStatusToSelectedItems('cancelled');
    } else {
      // the app side expects docs to have been actually deleted if they're not pax
      // we can safely assume that all fp_types are the same here
      var fpType = items[0].fp_type;
      var fpExtIds = items.map(function (_row) {
        return _row.fp_ext_id;
      });
      return bulkUpdateService.deleteDocs(eventId, event.node, fpType, fpExtIds).then(function () {
        $rootScope.setFlashMessage('The selected items were removed.');
        $scope.hideSelectedItemsInList();
        $scope.onDeleteOrDesactivate({
          selectedItems: items
        });
        return $route.reload();
      }, function () {
        return alert('Error! The selected items could not be deleted. Do they have ids?');
      });
    }
  };
  $scope.openModalDuplicate = function () {
    var items = getAllSelectedItems() || [];
    var fpType = getDocFpType();
    if (!items.length) {
      return;
    }
    metricsService.track('duplicate_start', {
      countSelectedItems: items.length,
      dataType: fpType
    });
    modalService.openModal({
      scope: {
        selectedItems: items,
        dataType: fpType,
        eventId: eventId
      },
      templateUrl: '/static/partials/components/modal-duplicate.html'
    }).then(function () {
      return _this.$timeout(function () {
        return _this.$window.location.reload();
      }, 1500);
    });
  };
  $scope.openModalBulkEdit = function () {
    var items = getAllSelectedItems() || [];
    var fpType = getDocFpType();
    if (!items.length || items.length < 2) {
      return;
    }
    metricsService.track('bulk_edit_start', {
      countSelectedItems: items.length,
      dataType: fpType
    });
    var metadataForType = metadataService.getCachedMetadataForTypeAsArray(eventId, fpType).filter(function (field) {
      return field._bulk_edit !== false && field.kind !== 'custom';
    });
    var metadataFields = metadataService.filterDuplicateTargetsExceptionsFields(metadataForType);
    modalService.openModal({
      scope: {
        selectedItems: items,
        dataType: fpType,
        metadataFields: metadataFields,
        eventId: eventId
      },
      templateUrl: '/static/partials/components/modal-bulk-edit.html'
    }).then(function () {
      // need a better way to reload the results
      // $route.reload seems to only reloads the list already created, and we need to refresh the whole list
      _this.$timeout(function () {
        return _this.$window.location.reload();
      }, 1500);
    });
  };
  $scope.openModalTimeShift = function () {
    var items = getAllSelectedItems() || [];
    var fpType = getDocFpType();
    if (!items.length) {
      return;
    }
    metricsService.track('time_shift_start', {
      countSelectedItems: items.length,
      dataType: fpType
    });
    modalService.openModal({
      scope: {
        selectedItems: items,
        eventId: eventId,
        dataType: fpType,
        timeShiftFields: $scope.timeShiftFields
      },
      templateUrl: '/static/partials/components/modal-time-shift.html'
    }).then(function () {
      return _this.$timeout(function () {
        return _this.$window.location.reload();
      }, 1500);
    });
  };
  var bulkAssociationFields = metadataService.getCachedMetadataForTypeAsArray(eventId, getDocFpType()).filter(function (mdField) {
    return mdField.kind === 'external' && (!mdField.kind_options || mdField.kind_options.type !== 'targets-exceptions');
  });
  $scope.getFieldsForBulkAssociation = function () {
    return bulkAssociationFields;
  };
  $scope.closeBulkAssociationModalAndPerformAssociation = function () {
    var selectedBulkAssociationField = $scope.selectedBulkAssociationField;
    if (!selectedBulkAssociationField) {
      return $scope.showBulkAssociationModal = false;
    }
    if (!confirm('This will associate the selected items with the one you have chosen. Are you sure?')) {
      return;
    }
    var getSelectedItemIdValue = function getSelectedItemIdValue(item, fieldDescriptor) {
      var idField = (fieldDescriptor.kind_options ? fieldDescriptor.kind_options.id_field : undefined) || 'fp_ext_id';
      return item[idField];
    };
    $scope.bulkAssociationInProgress = true;
    // loop through the selected items and make the appropriate associations
    var items = getAllSelectedItems();
    items.forEach(function (item) {
      var val = getSelectedItemIdValue($scope.selectedBulkAssociationItem, selectedBulkAssociationField);
      var singleDoc = (selectedBulkAssociationField.kind_options || {}).single_doc;
      if (singleDoc) {
        item[selectedBulkAssociationField.field] = val;
      } else {
        var arr = item[selectedBulkAssociationField.field];
        if (_.isArray(arr)) {
          if (!arr.includes(val)) {
            arr.push(val);
          }
        } else {
          item[selectedBulkAssociationField.field] = [val];
        }
      }
      if (item.isSelected) {
        return delete item.isSelected;
      }
    });
    // and save 'em all up
    return bulkUpdateService.createDocs(eventId, event.node, getDocFpType(), items, {
      keepId: true
    }).then(function () {
      return $scope.showBulkAssociationModal = $scope.bulkAssociationInProgress = false;
    }, function () {
      $scope.bulkAssociationInProgress = false;
      return alert('An error occurred.');
    });
  };
  $scope.$watch('selectedBulkAssociationField', function (selectedFieldDescriptor) {
    if (!_.isObject(selectedFieldDescriptor)) {
      return;
    }
    var type = (selectedFieldDescriptor.kind_options || {}).type;
    return eventService.getEventDocsByType(event, type).then(function (data) {
      return $scope.bulkAssociationItems = data.data;
    }, function () {
      return alert('An error occurred.');
    });
  });
};
tableItemSelectionActionsCtrl.$inject = ["$route", "$routeParams", "$scope", "$q", "$rootScope", "$window", "$timeout", "eventService", "metadataService", "bulkUpdateService", "metricsService", "modalService"];
module.exports = function () {
  return {
    transclude: true,
    scope: {
      rowCollection: '=',
      showPaxArrivedButton: '=',
      hideAssociateWithOtherItems: '=',
      somethingSelected: '=?',
      onStatusChange: '&',
      onDeleteOrDesactivate: '&',
      transclude: '='
    },
    templateUrl: '/static/partials/components/table-item-selection-actions.html',
    controller: tableItemSelectionActionsCtrl
  };
};